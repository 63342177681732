import React from "react"
import Navbar from "react-bootstrap/Navbar"

import logo from "./brand.jpg"

import "./index.scss"

const Navi = (props) => {

  return (
    <Navbar expand="md" bg="light-trans">
      <div className="container main-nav-container">
        <Navbar.Brand href="/">
          <img alt="stuarts logo" src={logo} className="d-inline-block align-top" />
        </Navbar.Brand>
      </div>
    </Navbar>
  )
}

export default Navi
