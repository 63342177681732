import React from "react"
import PropTypes from "prop-types"

import Navi from "./Navi"
import Header from "./Header"
import Footer from "./Footer"

import "./index.scss"

const Layout = ({ children, location }) => (
  <>
    <Navi location={location} />
    <Header />
    <main className="container">{children}</main>
    <Footer />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
