import React from "react"
import { Link } from "gatsby"

import "./index.scss"

const Footer = () => (
  <>
    <div className="footer-top">
    </div>
    <div className="footer-base">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 footer-base-left">
            <nav className="nav">
              <Link to="/" className="nav-link">
                Stuarts Coaches 2023
              </Link>
              <Link to="/terms-of-use" className="nav-link">
                Terms of Use
              </Link>
              <Link to="/privacy-policy" className="nav-link">
                Privacy Policy
              </Link>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default Footer
